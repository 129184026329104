import { optionsStorage } from "@/api/options-storage";
import isMobile from "ismobilejs";
import store from "@/store";
import { GAMBUSAKI_ZEROS_MATRIX, PAYMENT_ODDS, TICKET_COMBINATIONS } from "@/constants";
import { GameId } from "@/types/user";

// const i18n = useI18n();

// ------------------------------------------------------

export const debounce = <F extends (...args: any) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  const debounced = (...args: any) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function formatMoney(value: number, format: "round" | "std" | "fixed" = "round"): string {
  let val: number = value / 100;
  let result: string = "",
    suffix: string = "";
  if (format === "round") {
    if (val < 100000) {
      // show with fractions
      result = val.toFixed((val ^ 0) === val ? 0 : 2).replace(/(\.\d)0$/g, "$1");
    } else if (val < 1000000) {
      // drop fractions
      result = Math.floor(val).toString();
    } else {
      // show with 'k'
      result = Math.floor(val / 1000).toString();
      suffix = "k";
    }
  } else if (format === "std") {
    result = val.toFixed(2);
  } else {
    return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  const temp = (value / 100).toFixed(2).split(".");
  temp[0] = temp[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return temp.join(".");
}

export const USER_TOKEN = "user_token";
export type RestoreToken = { type: "simple"; value: any } | any;

export function getToken(token?: string): any {
  let payload: any;
  // get payload from url token
  if (token) {
    try {
      payload = JSON.parse(atob(token));
    } catch (e) {
      throw new Error("Invalid token");
    }
  } else {
    throw new Error("Auth failed: no token");
  }
  return payload;
}

export function getRestoreToken(): RestoreToken {
  let payload: any;
  const user_token = optionsStorage.getItem(USER_TOKEN);
  if (user_token) {
    try {
      payload = JSON.parse(atob(user_token));
    } catch (e) {
      throw new Error("Invalid refresh token");
    }
  } else {
    throw new Error("Auth failed: no token");
  }
  return payload;
}

export const Metrics = {
  reachMetric(target: string, params: any = undefined) {
    console.debug("reachMetric", target);
  },
  // yandexGoal(target: string, time: number) {
  //   (window as any).ym(57282163, "reachGoal", target, { order_price: time });
  //   (window as any).ym(62653438, "reachGoal", target, { order_price: time });
  // },
  // googleGoal(target: string, time: number) {
  //   (window as any).gtag("event", target, {
  //     event_label: target,
  //     event_category: "test",
  //     non_interaction: true,
  //     value: time,
  //   });
  // },
};

export function getCurrencyIcon(currency: number): string {
  switch (currency) {
    //Российский рубль
    case 643:
      return "₽";
    //Американский доллар
    case 840:
      return "$";
    //Азербайджанский манат
    case 944:
      return "₼";
    //Украинская гривна
    case 980:
      return "₴";
    //Казахстанский тенге
    case 398:
      return "₸";
    //Евро
    case 978:
      return "€";
    //Аргентинское песо
    case 32:
      return "A$";
    //Колумбийское песо
    case 170:
      return "C$";
    //Индийская рупия
    case 356:
      return "₹";
    //Индонезийская рупия
    case 360:
      return "₨";
    //Перуанский соль
    case 604:
      return "S/";
    //Узбекский сум
    case 860:
      return "сўм";
    //Белорусский рубль
    case 933:
      return "Br";
    //Турецкая лира
    case 949:
      return "₺";
    //Болгарский лев
    case 975:
      return "лв";
    //Бразильский реал
    case 986:
      return "R$";
    //Польский злотый
    case 985:
      return "zł";
    //Бонусный рубль
    case 1002:
      return "Rbon";
    case 1004:
      return "SB";
    //Сомони
    case 972:
      return "с.";
    //Китайский Юань
    case 156:
      return "¥";
    //Кыргызский Сом
    case 417:
      return "с";
    //Фунт стерлингов Великобритании
    case 826:
      return "£";
    //Молдавский Лей
    case 498:
      return "L";
    //Грузинский Лари
    case 981:
      return "₾";
    //Тайский бат
    case 764:
      return "B";
    //Южноафриканский рэнд
    case 710:
      return "R";
    //Кенийский шиллинг
    case 404:
      return "KSh";
    //Новозедандский доллар
    case 554:
      return "NZ$";
    //Швейцарский франк
    case 756:
      return "₣";
    default:
      return "€";
  }
}

/**
 * Check if incoming notification must be dropped
 * Now drop "freebet-created" notifications if WELCOME_FREEBET disabled in features
 * @param el
 */

const marketTitleParts: { [key in string]: string[] } = {};

export function isPageHidden(): boolean {
  let hidden: string | undefined;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
  } else if (typeof (document as any).msHidden !== "undefined") {
    hidden = "msHidden";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    hidden = "webkitHidden";
  }
  return hidden === undefined || (document as any)[hidden];
}

export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

export const getRandomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// перемешивает и возвращает новый массив
export const shuffleArray = <T extends any[]>(array: T): T => {
  const tempArray = array;
  for (let i = tempArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
  }
  return tempArray;
};

function calcCtx(el?: HTMLElement): CalcContext {
  if (el) {
    const rect = el.getBoundingClientRect();

    return {
      width: rect.width,
      height: rect.height,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      htmlFontSize: parseFloat(window.getComputedStyle(document.querySelector("html")!).fontSize!),
    };
  } else {
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      htmlFontSize: parseFloat(window.getComputedStyle(document.querySelector("html")!).fontSize!),
    };
  }
}

interface CalcContext {
  width: number;
  height: number;
  viewportWidth: number;
  viewportHeight: number;
  /** font-size on <html> element */
  htmlFontSize: number;
}

// units -> pixels
export const Absolute = {
  /** browser version of pixel */
  px: 1,
  /** One centimeter. 1cm = 96px/2.54 */
  cm: 96 / 2.54,
  /** One millimeter. 1mm = 1/10th of 1cm */
  mm: 96 / 25.4,
  /** One quarter of a millimeter. 1Q = 1/40th of 1cm */
  Q: 96 / 101.6,
  /** One inch. 1in = 2.54cm = 96px */
  in: 96,
  /** One pica. 1pc = 12pt = 1/6th of 1in */
  pc: 96 / 6,
  /** One point. 1pt = 1/72nd of 1in */
  pt: 96 / 72,
};

type AbsoluteUnit = keyof typeof Absolute;

// units ->(calc context)-> pixels
const Relative = {
  /** Equal to 1% of the height of the viewport */
  vh: (count: number = 1, ctx?: { viewportHeight: number }) => {
    return ((ctx ? ctx.viewportHeight : window.innerHeight) / 100) * count;
  },
  /** Equal to 1% of the width of the viewport */
  vw: (count: number = 1, ctx?: { viewportWidth: number }) => {
    return ((ctx ? ctx.viewportWidth : window.innerWidth) / 100) * count;
  },
  /** 1/100th of the smallest viewport side */
  vmin: (count: number = 1, ctx?: { viewportWidth: number; viewportHeight: number }) => {
    return (
      ((ctx
        ? Math.min(ctx.viewportWidth, ctx.viewportHeight)
        : Math.min(window.innerWidth, window.innerHeight)) /
        100) *
      count
    );
  },
  /** 1/100th of the largest viewport side */
  vmax: (count: number = 1, ctx?: { viewportWidth: number; viewportHeight: number }) => {
    return (
      ((ctx
        ? Math.max(ctx.viewportWidth, ctx.viewportHeight)
        : Math.max(window.innerWidth, window.innerHeight)) /
        100) *
      count
    );
  },
  /** Represents the font-size of <html> element */
  rem: (count: number = 1, ctx?: { htmlFontSize: number }) => {
    return (
      (ctx
        ? ctx.htmlFontSize
        : parseFloat(window.getComputedStyle(document.querySelector("html")!).fontSize!)) * count
    );
  },
  /** percent of width */
  "%w": (count: number = 1, ctx?: { width: number }) => {
    return ((ctx ? ctx.width : document.body.clientWidth) / 100) * count;
  },
  /** percent of height */
  "%h": (count: number = 1, ctx?: { height: number }) => {
    return ((ctx ? ctx.height : document.body.clientHeight) / 100) * count;
  },
};

export const truncTwo = (number: number) => Math.trunc(number * 100) / 100;

export const Units = {
  ...Relative,
  ...Absolute,
};

export type RelativeUnit = keyof typeof Relative;

export type Unit = AbsoluteUnit | RelativeUnit;

export function convert(
  count: number,
  fromUnits: Unit,
  toUnits: Unit,
  ctx: CalcContext = calcCtx()
): number {
  const baseUnit = Units[fromUnits];
  const basePx = typeof baseUnit === "function" ? baseUnit(count, ctx) : baseUnit * count;

  const dstUnit = Units[toUnits];
  const dstBasePx = typeof dstUnit === "function" ? dstUnit(1, ctx) : dstUnit;

  return basePx / dstBasePx;
}

window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;

export const getBackgroundMusic = (gameId: GameId) => {
  switch (gameId) {
    case "loto-pamir-legends":
      return {
        main: "main-theme-pamir-legends",
        bonus: "bonus-theme-pamir-legends",
      };
    case "loto-pearl":
      return {
        main: "main-theme-pearl",
        bonus: "bonus-theme-pearl",
      };
    case "loto-misr":
      return {
        main: "main-theme-misr",
        bonus: "bonus-theme-misr",
      };
    case "loto-silk-way":
      return {
        main: "main-theme-silk-way",
        bonus: "bonus-theme-silk-way",
      };
    case "loto-astrologer":
      return {
        main: "main-theme-astrologer",
        bonus: "bonus-theme-astrologer",
      };
    case "loto-khoja":
      return {
        main: "main-theme-khoja",
        bonus: "bonus-theme-khoja",
      };
    case "loto-kissaho":
      return {
        main: "main-theme-kissaho",
        bonus: "bonus-theme-kissaho",
      };
    default:
      return null;
  }
};

export const AudioUtils = {
  audioContext: undefined as unknown as AudioContext,
  buffers: {} as {
    [id in string]: AudioBuffer;
  },
  sounds: {} as {
    [id in string]: AudioBufferSourceNode;
  },
  backgroundMusic: {} as {
    [id in string]: AudioBufferSourceNode;
  },
  getAudioContext(): AudioContext {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    return this.audioContext;
  },
  fixIOSWTF() {
    // from https://gist.github.com/kus/3f01d60569eeadefe3a1
    var fixAudioContext = function () {
      AudioUtils.resumeAudioContext();
      // Create empty buffer
      var buffer = AudioUtils.getAudioContext().createBuffer(1, 1, 22050);
      var source = AudioUtils.getAudioContext().createBufferSource();
      source.buffer = buffer;
      // Connect to output (speakers)
      source.connect(AudioUtils.getAudioContext().destination);
      // Play sound
      source.start(0);
      // Remove events
      document.removeEventListener("touchstart", fixAudioContext);
      document.removeEventListener("touchend", fixAudioContext);
    };
    // iOS 6-8
    document.addEventListener("touchstart", fixAudioContext);
    // iOS 9
    document.addEventListener("touchend", fixAudioContext);
    console.debug("Unmute AudioContext for iOS", this.getAudioContext().state);
  },
  resumeAudioContext() {
    const ctx = AudioUtils.getAudioContext();
    if (ctx.state === "suspended") {
      ctx
        .resume()
        .then(() => console.log("AudioUtils.resumeAudioContext success"))
        .catch((err) => console.error("AudioUtils.resumeAudioContext", err));
    }
  },
  loadSound(url: string, id: string): Promise<AudioBuffer> {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer";

    return new Promise((resolve, reject) => {
      // Decode asynchronously
      request.onload = () => {
        const p = this.getAudioContext().decodeAudioData(request.response);
        p.then((buffer) => {
          resolve(buffer);
          this.buffers[id] = buffer;
        }).catch((err) => {
          console.warn("AudioUtils.decodeAudioData failed", err);
          console.warn(err);
          reject(err);
        });
      };
      request.onerror = (err) => {
        console.warn("AudioUtils.loadSound failed", err);
        reject(err);
      };
      request.send();
    });
  },
  playSound(
    buffer: AudioBuffer | string,
    time: number,
    duration: number = 0,
    loop: boolean = false,
    onEnded?: () => void
  ) {
    if (store.state.userData.settings.sound === "off") {
      return;
    }
    if (
      this.getAudioContext().state === "suspended" ||
      (this.getAudioContext().state as any) === "interrupted"
    ) {
      this.getAudioContext()
        .resume()
        .then(() => {
          this.playSound(buffer, time, duration, loop, onEnded);
        });
      return;
    }
    const source = (this.sounds[buffer as string] = this.getAudioContext().createBufferSource());
    source.buffer = buffer instanceof AudioBuffer ? buffer : this.buffers[buffer];
    source.connect(this.getAudioContext().destination);
    source.start(time);
    source.loop = loop;
    if (!!duration) {
      source.stop(this.getAudioContext().currentTime + duration);
    }
    source.onended = () => {
      if (onEnded) {
        onEnded();
      }
      delete this.sounds[buffer as string];
    };

    this.sounds[buffer as string] = source;
  },
  stopSound(buffer: AudioBuffer | string) {
    const source = this.sounds[buffer as string];
    if (source) {
      source.stop();
      delete this.sounds[buffer as string];
    }
  },

  // Фоновая музыка
  playBackgroundMusic(gameId: GameId, theme: "main" | "bonus" = "main") {
    if (store.state.userData.settings.background_sound === "off") {
      return;
    }

    const music = getBackgroundMusic(gameId);

    if (!music) {
      return;
    }

    const bufferId = music[theme];
    if (!bufferId) {
      return;
    }

    if (this.backgroundMusic[bufferId]) {
      return;
    }

    const buffer = this.buffers[bufferId];
    if (!buffer) {
      console.error(`Buffer ${bufferId} not found`);
      return;
    }

    const source = this.getAudioContext().createBufferSource();
    source.buffer = buffer;
    source.loop = true;
    source.connect(this.getAudioContext().destination);
    source.start(0);

    this.backgroundMusic[bufferId] = source;
  },
  stopBackgroundMusic(gameId: GameId, theme: "main" | "bonus" = "main") {
    const music = getBackgroundMusic(gameId);
    if (!music) {
      return;
    }
    const buffer = music[theme];
    if (!buffer) {
      return;
    }

    const source = this.backgroundMusic[buffer];
    if (source) {
      try {
        source.stop();
      } catch (e) {
        console.error(`Error stopping sound for ${gameId}, theme: ${theme}:`, e);
      }

      delete this.backgroundMusic[buffer];
    }
  },
  stopAllBackgroundMusic() {
    // Останавливаем все фоновые темы для игр, поддерживающих музыку
    this.stopBackgroundMusic("loto-pamir-legends", "main");
    this.stopBackgroundMusic("loto-pamir-legends", "bonus");
    this.stopBackgroundMusic("loto-pearl", "main");
    this.stopBackgroundMusic("loto-pearl", "bonus");
    this.stopBackgroundMusic("loto-misr", "main");
    this.stopBackgroundMusic("loto-misr", "bonus");
    this.stopBackgroundMusic("loto-khoja", "main");
    this.stopBackgroundMusic("loto-khoja", "bonus");
    this.stopBackgroundMusic("loto-astrologer", "main");
    this.stopBackgroundMusic("loto-astrologer", "bonus");
    this.stopBackgroundMusic("loto-kissaho", "main");
    this.stopBackgroundMusic("loto-kissaho", "bonus");
  },

  gameSupportsBackgroundMusic(gameId: GameId) {
    return [
      "loto-pamir-legends",
      "loto-pearl",
      "loto-misr",
      "loto-khoja",
      "loto-astrologer",
      "loto-kissaho",
    ].includes(gameId);
  },

  updateSoundState(newVal?: "sound" | "background_sound") {
    const {
      sound,
      background_sound: backgroundSound,
      mevaho_mode: scoreboardMode,
    } = store.state.userData.settings;
    const gameId = store.state.currentGameId;
    const isBonusGame = store.state.bonus.isOn;

    if (sound === "off" && backgroundSound === "off") {
      this.stopAllBackgroundMusic();
      return;
    }

    if (newVal === "sound" || !newVal) {
      if (sound === "on") {
        console.log("Sound effects are enabled");
        // Логика включения звуковых эффектов
      } else {
        console.log("Sound effects are disabled");
        // Логика выключения звуковых эффектов
      }
    }

    if (newVal === "background_sound" || !newVal) {
      if (backgroundSound === "on") {
        if (this.gameSupportsBackgroundMusic(gameId) && scoreboardMode === "fruit") {
          const theme = isBonusGame ? "bonus" : "main";

          AudioUtils.stopBackgroundMusic(gameId, "main");
          if (isBonusGame) {
            AudioUtils.stopBackgroundMusic(gameId, "bonus");
          }
          AudioUtils.playBackgroundMusic(gameId, theme);
        } else {
          console.warn(`Игра ${gameId} или режим ${scoreboardMode} не поддерживают фоновую музыку`);
        }
      } else {
        this.stopAllBackgroundMusic();
      }
    }
  },

  /* stopAllSounds() {
    console.log("context", this.getAudioContext().state);
    for (const id in this.sounds) {
      const source = this.sounds[id];
      if (source && source.buffer) {
        try {
          source.stop();
        } catch (e) {
          console.error(`Error stopping sound with id ${id}:`, e);
        }
      }
    }
    this.sounds = {};
    this.buffers = {};
    this.getAudioContext()
      .suspend()
      .then(() => {
        this.getAudioContext().close();
        this.audioContext = undefined;
      });
    console.log("context1", this.getAudioContext().state);
  },*/
};

AudioUtils.loadSound(require("@/audio/slot-carpet.mp3"), "slot-carpet");
AudioUtils.loadSound(require("@/audio/win-drop-sound.mp3"), "win-drop-sound");
AudioUtils.loadSound(
  require("@/audio/doubling-button-appearance.ogg"),
  "doubling-button-appearance"
);
AudioUtils.loadSound(require("@/audio/stop-autoplay.mp3"), "stop-autoplay");
AudioUtils.loadSound(require("@/audio/button-press-sound.mp3"), "button-press-sound");
AudioUtils.loadSound(require("@/audio/launch-autoplay-button.mp3"), "launch-autoplay-button");
AudioUtils.loadSound(require("@/audio/receipt-breakdown-winning.mp3"), "receipt-breakdown-winning");
AudioUtils.loadSound(require("@/audio/receipt-win-drop-sound.mp3"), "receipt-win-drop-sound");
AudioUtils.loadSound(require("@/audio/zero-drop-highlight.mp3"), "zero-drop-highlight");
AudioUtils.loadSound(require("@/audio/breakdown-chests-loss.mp3"), "breakdown-chests-loss");
AudioUtils.loadSound(require("@/audio/breakdown-chests-win.mp3"), "breakdown-chests-win");
AudioUtils.loadSound(require("@/audio/fruit-short.mp3"), "fruit-short");
AudioUtils.loadSound(require("@/audio/fruit-long.mp3"), "fruit-long");
AudioUtils.loadSound(require("@/audio/fruit-middle.mp3"), "fruit-middle");
AudioUtils.loadSound(require("@/audio/digit-short.mp3"), "digit-short");
AudioUtils.loadSound(
  require("@/audio/pressing-bet-amount-button.mp3"),
  "pressing-bet-amount-button"
);
AudioUtils.loadSound(require("@/audio/receipt-erase.mp3"), "receipt-erase");

// Gambusaki
AudioUtils.loadSound(require("@/audio/gambusaki/slot-carpet.wav"), "slot-carpet-gambusaki");
AudioUtils.loadSound(
  require("@/audio/gambusaki-gold/slot-carpet.mp3"),
  "slot-carpet-gambusaki-gold"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/receipt-breakdown-winning.mp3"),
  "receipt-breakdown-winning-gambusaki"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/receipt-win-drop-sound.mp3"),
  "receipt-win-drop-sound-gambusaki"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/breakdown-chests-loss.mp3"),
  "breakdown-chests-loss-gambusaki"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/breakdown-chests-win.mp3"),
  "breakdown-chests-win-gambusaki"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/doubling-dropout-viola.mp3"),
  "doubling-dropout-viola"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/doubling-dropout-drum.mp3"),
  "doubling-dropout-drum"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/doubling-dropout-guitar.mp3"),
  "doubling-dropout-guitar"
);
AudioUtils.loadSound(require("@/audio/gambusaki/doubling-dropout-sax.mp3"), "doubling-dropout-sax");
AudioUtils.loadSound(
  require("@/audio/gambusaki/doubling-dropout-sing.mp3"),
  "doubling-dropout-sing"
);
AudioUtils.loadSound(require("@/audio/gambusaki/getting-free-tickets.mp3"), "getting-free-tickets");
AudioUtils.loadSound(
  require("@/audio/gambusaki/getting-free-tickets-short.mp3"),
  "getting-free-tickets-short"
);
AudioUtils.loadSound(
  require("@/audio/gambusaki/doubling-dropout-jazz.mp3"),
  "doubling-dropout-jazz"
);

// Khoja

AudioUtils.loadSound(require("@/audio/misr/misr-carpet-sound.mp3"), "slot-carpet-khoja");

//AudioUtils.loadSound(require("@/audio/khoja/slot-carpet-khoja.mp3"), "slot-carpet-khoja");

AudioUtils.loadSound(
  require("@/audio/khoja/receipt-win-drop-sound.mp3"),
  "receipt-breakdown-winning-khoja"
);
AudioUtils.loadSound(
  require("@/audio/khoja/breakdown-chests-loss.mp3"),
  "breakdown-chests-loss-khoja"
);
AudioUtils.loadSound(
  require("@/audio/khoja/breakdown-chests-win.mp3"),
  "breakdown-chests-win-khoja"
);
AudioUtils.loadSound(
  require("@/audio/khoja/getting-free-tickets.mp3"),
  "getting-free-tickets-short-khoja"
);

AudioUtils.loadSound(require("@/audio/khoja/khoja-main-theme.mp3"), "main-theme-khoja");

AudioUtils.loadSound(require("@/audio/khoja/khoja-bonus-theme.mp3"), "bonus-theme-khoja");

AudioUtils.loadSound(require("@/audio/khoja/win-one-khoja.mp3"), "win-one-khoja");

AudioUtils.loadSound(require("@/audio/khoja/win-three-khoja.mp3"), "win-three-khoja");

// start unmute hack for iOS
if (isMobile().apple.phone) {
  AudioUtils.fixIOSWTF();
}

// Kissaho

AudioUtils.loadSound(require("@/audio/kissaho/carpet-kissaho.mp3"), "slot-carpet-kissaho");

AudioUtils.loadSound(
  require("@/audio/khoja/receipt-win-drop-sound.mp3"),
  "receipt-breakdown-winning-kissaho"
);
AudioUtils.loadSound(
  require("@/audio/kissaho/breakdown-chests-loss-kissaho.mp3"),
  "breakdown-chests-loss-kissaho"
);
AudioUtils.loadSound(
  require("@/audio/kissaho/breakdown-chests-win-kissaho.mp3"),
  "breakdown-chests-win-kissaho"
);
AudioUtils.loadSound(
  require("@/audio/kissaho/getting-free-tickets-kissaho.mp3"),
  "getting-free-tickets-short-kissaho"
);

AudioUtils.loadSound(require("@/audio/kissaho/kissaho-main-theme_out.mp3"), "main-theme-kissaho");

AudioUtils.loadSound(require("@/audio/kissaho/kissaho-bonus-theme.mp3"), "bonus-theme-kissaho");

AudioUtils.loadSound(require("@/audio/kissaho/win-one-kissaho.mp3"), "win-one-kissaho");
AudioUtils.loadSound(require("@/audio/kissaho/win-three-kissaho.mp3"), "win-three-kissaho");

// Silk-way

AudioUtils.loadSound(require("@/audio/silk-way/slot-carpet-silk-way.mp3"), "slot-carpet-silk-way");

AudioUtils.loadSound(
  require("@/audio/silk-way/receipt-win-drop-sound-silk-way.mp3"),
  "receipt-breakdown-winning-silk-way"
);
AudioUtils.loadSound(
  require("@/audio/silk-way/breakdown-chests-loss-silk-way.mp3"),
  "breakdown-chests-loss-silk-way"
);
AudioUtils.loadSound(
  require("@/audio/silk-way/breakdown-chests-win-silk-way.mp3"),
  "breakdown-chests-win-silk-way"
);
AudioUtils.loadSound(
  require("@/audio/silk-way/getting-free-tickets-silk-way.mp3"),
  "getting-free-tickets-short-silk-way"
);

// PAMIR LEGENDS

AudioUtils.loadSound(
  require("@/audio/pamir/carpet-pamir-legends.mp3"),
  "slot-carpet-pamir-legends"
);

AudioUtils.loadSound(
  require("@/audio/pamir/receipt-win-drop-sound-pamir-legends.mp3"),
  "receipt-breakdown-winning-pamir-legends"
);
AudioUtils.loadSound(
  require("@/audio/pamir/breakdown-chests-loss-pamir-legends.mp3"),
  "breakdown-chests-loss-pamir-legends"
);
AudioUtils.loadSound(
  require("@/audio/pamir/breakdown-chests-win-pamir-legends.mp3"),
  "breakdown-chests-win-pamir-legends"
);
AudioUtils.loadSound(
  require("@/audio/pamir/getting-free-tickets-pamir-legends.mp3"),
  "getting-free-tickets-short-pamir-legends"
);

AudioUtils.loadSound(
  require("@/audio/pamir/main-theme-pamir-legends.mp3"),
  "main-theme-pamir-legends"
);

AudioUtils.loadSound(
  require("@/audio/pamir/bonus-theme-pamir-legends.mp3"),
  "bonus-theme-pamir-legends"
);

AudioUtils.loadSound(require("@/audio/pamir/win-one-pamir-legends.mp3"), "win-one-pamir-legends");
AudioUtils.loadSound(require("@/audio/pamir/win-two-pamir-legends.mp3"), "win-two-pamir-legends");
AudioUtils.loadSound(
  require("@/audio/pamir/win-three-pamir-legends.mp3"),
  "win-three-pamir-legends"
);
AudioUtils.loadSound(
  require("@/audio/pamir/win-one-pamir-legends-bonus.mp3"),
  "win-one-pamir-legends-bonus"
);
AudioUtils.loadSound(
  require("@/audio/pamir/win-three-pamir-legends-bonus.mp3"),
  "win-three-pamir-legends-bonus"
);

// pearl

AudioUtils.loadSound(require("@/audio/pearl/slot-ocean-carpet.mp3"), "slot-carpet-pearl");
AudioUtils.loadSound(
  require("@/audio/pearl/slot-bonus-carpet-pearl.mp3"),
  "bonus-slot-carpet-pearl"
);
AudioUtils.loadSound(
  require("@/audio/pearl/receipt-win-drop-sound-pearl.mp3"),
  "receipt-breakdown-winning-pearl"
);
AudioUtils.loadSound(
  require("@/audio/pearl/breakdown-chests-loss-pearl.mp3"),
  "breakdown-chests-loss-pearl"
);
AudioUtils.loadSound(
  require("@/audio/pearl/breakdown-chests-win-pearl.mp3"),
  "breakdown-chests-win-pearl"
);
AudioUtils.loadSound(
  require("@/audio/pearl/getting-free-tickets-pearl.mp3"),
  "getting-free-tickets-short-pearl"
);

AudioUtils.loadSound(require("@/audio/pearl/main-theme-pearl.mp3"), "main-theme-pearl");

AudioUtils.loadSound(require("@/audio/pearl/bonus-theme-pearl.mp3"), "bonus-theme-pearl");

AudioUtils.loadSound(require("@/audio/pearl/one-win-pearl.mp3"), "win-one-pearl");

AudioUtils.loadSound(require("@/audio/pearl/three-win-pearl.mp3"), "win-three-pearl");

// ASTROLOGER

AudioUtils.loadSound(
  require("@/audio/astrologer/astrologer-carpet-nice.mp3"),
  "slot-carpet-astrologer"
);
AudioUtils.loadSound(
  require("@/audio/astrologer/astrologer-carpet-nice.mp3"),
  "bonus-slot-carpet-astrologer"
);
AudioUtils.loadSound(
  require("@/audio/astrologer/receipt-win-drop-sound.mp3"),
  "receipt-breakdown-winning-astrologer"
);
AudioUtils.loadSound(
  require("@/audio/astrologer/breakdown-chests-loss-astro.mp3"),
  "breakdown-chests-loss-astrologer"
);
AudioUtils.loadSound(
  require("@/audio/astrologer/breakdown-chests-win-astro.mp3"),
  "breakdown-chests-win-astrologer"
);
AudioUtils.loadSound(
  require("@/audio/astrologer/getting-free-tickets-astro.mp3"),
  "getting-free-tickets-short-astrologer"
);

AudioUtils.loadSound(
  require("@/audio/astrologer/astrologer-main-theme.mp3"),
  "main-theme-astrologer"
);

AudioUtils.loadSound(
  require("@/audio/astrologer/astrologer-bonus-theme.mp3"),
  "bonus-theme-astrologer"
);

AudioUtils.loadSound(require("@/audio/astrologer/one-win-astro.mp3"), "win-one-astrologer");

AudioUtils.loadSound(require("@/audio/astrologer/three-win-astro.mp3"), "win-three-astrologer");

// MISR

AudioUtils.loadSound(require("@/audio/misr/misr-carpet-sound.mp3"), "slot-carpet-misr");

AudioUtils.loadSound(
  require("@/audio/misr/receipt-win-drop-sound-misr.mp3"),
  "receipt-breakdown-winning-misr"
);
AudioUtils.loadSound(
  require("@/audio/misr/breakdown-chests-loss-misr.mp3"),
  "breakdown-chests-loss-misr"
);
AudioUtils.loadSound(
  require("@/audio/misr/breakdown-chests-win-misr.mp3"),
  "breakdown-chests-win-misr"
);
AudioUtils.loadSound(
  require("@/audio/misr/getting-free-tickets-misr.mp3"),
  "getting-free-tickets-short-misr"
);

AudioUtils.loadSound(require("@/audio/misr/misr-main-theme.mp3"), "main-theme-misr");

AudioUtils.loadSound(require("@/audio/misr/misr-bonus-theme.mp3"), "bonus-theme-misr");

AudioUtils.loadSound(require("@/audio/misr/misr-win-sound-one.mp3"), "win-one-misr");

AudioUtils.loadSound(require("@/audio/misr/misr-win-sound-three.mp3"), "win-three-misr");
