
import { defineComponent } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import store from "@/store";

export default defineComponent({
  name: "desktop",
  components: { MenuWrapper, MainContainer },
  data() {
    return {
      currentPamirBackground: 0,
      pamirDay: require("@/images/backgrounds/bg-pamir-legends.webp"),
      pamirNight: require("@/images/backgrounds/pamir-legends-bonus-background-night.webp"),
      pamirStars: require("@/images/backgrounds/pamir-legends-stars.webp"),
      pamirBackgrounds: [
        require("@/images/backgrounds/bg-pamir-legends.webp"),
        require("@/images/backgrounds/pamir-legends-bonus-background-night.webp"),
      ],
      preloadedImages: [] as HTMLImageElement[],
    };
  },
  mounted() {
    this.isPamir && this.preloadImages();
  },
  computed: {
    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isBonusGamePamirLegends(): boolean {
      return store.state.ui.showBonusPersons && store.state.currentGameId === "loto-pamir-legends";
    },
    pamirBackgroundsStyle(): any {
      return {
        backgroundImage: `url(${this.pamirBackgrounds[this.currentPamirBackground]})`,
        backgroundSize: "100% 100%",
      };
    },
  },
  methods: {
    changeBackground() {
      this.currentPamirBackground = this.currentPamirBackground === 0 ? 1 : 0;
      console.log("currentPamirBackground", this.currentPamirBackground);
    },
    preloadImages() {
      const images = [this.pamirDay, this.pamirNight, this.pamirStars];
      this.pamirBackgrounds.forEach((src) => {
        const img = new Image();
        img.src = src;
        this.preloadedImages.push(img);
        console.log("preload", this.preloadedImages);
      });
    },
  },
  watch: {
    isBonusGamePamirLegends(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.changeBackground();
      } else if (!newVal && oldVal) {
        this.changeBackground();
      }
    },
  },
});
