import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f6558c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auto-play__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeChecked && _ctx.changeChecked(...args))),
    class: _normalizeClass(["auto-play", {
      '--glow': _ctx.autoGame,
      '--disabled': _ctx.disabled || _ctx.isBetstop || _ctx.isDoublingOn || (_ctx.controlDisabled && !_ctx.autoGame),
    }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("autoGame")), 1)
  ], 2))
}