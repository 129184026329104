
import { defineComponent } from "vue";

export default defineComponent({
  name: "simple-button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {},
});
