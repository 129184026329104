import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d8967ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "purchase-panel" }
const _hoisted_2 = { class: "purchase-panel__mobile" }
const _hoisted_3 = {
  key: 0,
  class: "purchase-panel__container"
}
const _hoisted_4 = {
  key: 1,
  class: "purchase-panel__container"
}
const _hoisted_5 = { class: "purchase-panel__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputControl = _resolveComponent("InputControl")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isSplitAvailable && _ctx.isDoublingOn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_InputControl, {
              title: _ctx.$t('amountToDouble'),
              value: _ctx.currentAmount,
              "show-currency": ""
            }, null, 8, ["title", "value"]),
            _createVNode(_component_InputControl, {
              center: true,
              title: _ctx.$t('deferredBalance'),
              value: _ctx.deferredBalance,
              "show-currency": ""
            }, null, 8, ["title", "value"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_InputControl, {
              title: _ctx.$t('bet'),
              value: _ctx.newNominal
            }, null, 8, ["title", "value"]),
            _createVNode(_component_InputControl, {
              center: true,
              title: _ctx.$t('lotteryCombination'),
              value: _ctx.newCombinationNumber
            }, null, 8, ["title", "value"])
          ])),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputControl, {
          title: _ctx.$t('sumAmount'),
          "show-currency": !!_ctx.sumAmount,
          value: _ctx.sumAmount
        }, null, 8, ["title", "show-currency", "value"]),
        (_ctx.isSplitAvailable && _ctx.isDoublingOn)
          ? (_openBlock(), _createBlock(_component_InputControl, {
              key: 0,
              title: _ctx.$t('balance'),
              value: _ctx.balance,
              "show-currency": ""
            }, null, 8, ["title", "value"]))
          : (_openBlock(), _createBlock(_component_InputControl, {
              key: 1,
              title: _ctx.$t('ticketWinning'),
              "show-currency": !!_ctx.sumPaid,
              value: _ctx.sumPaid,
              glow: !!_ctx.sumPaid
            }, null, 8, ["title", "show-currency", "value", "glow"]))
      ])
    ])
  ]))
}