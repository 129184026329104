
import { defineComponent, PropType } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import InputNice from "@/components/UI/InputNice.vue";
import store from "@/store";
import DoublingBoxes from "@/components/DoublingBoxes.vue";
import DoublingBoxesSilkWay from "@/components/DoublingBoxesSilkWay.vue";
import DoublingBoxesMisr from "@/components/DoublingBoxesMisr.vue";
import DoublingBoxesPamir from "@/components/DoublingBoxesPamir.vue";
import DoublingBoxesAstrologer from "@/components/DoublingBoxesAstrologer.vue";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import LastBoxes from "@/components/LastBoxes.vue";
import InputFlame from "@/components/UI/InputFlame.vue";
import DoublingBoxesMevahoGold from "@/components/DoublingBoxesMevahoGold.vue";
import ScoreboardWrapper from "@/components/Scoreboards/ScoreboardWrapper.vue";
import LastBoxesRow from "@/components/Mobile/LastBoxesRow.vue";

export default defineComponent({
  name: "doubling-desktop-mevaho-gold",
  props: {},
  components: {
    InputFlame,
    ControlPanel,
    DoublingBoxes,
    InputNice,
    DoublingHeader,
    MenuWrapper,
    MainContainer,
    LastBoxes,
    DoublingBoxesMevahoGold,
    ScoreboardWrapper,
    LastBoxesRow,
  },
  data() {
    return {
      mevahoGoldExpectedWinnings: [] as number[],
    };
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
    isGambusakiGold(): boolean {
      return store.state.currentGameId === "loto-gambusaki-gold-9";
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },

    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isAstro(): boolean {
      return store.state.currentGameId === "loto-astrologer";
    },
    isMevahoGold(): boolean {
      return store.state.currentGameId === "loto-mevaho-gold";
    },
    expectedWinningsByGame(): number[] {
      return this.isMevahoGold ? this.mevahoGoldExpectedWinnings : this.expectedWinnings;
    },
    imgMan(): string {
      return this.isGambusakiGold
        ? require("@/images/man-gold.webp")
        : this.isKissaho
        ? require("@/images/ui/man-kissaho.webp")
        : this.isPearl
        ? require("@/images/ui/man-pearl.webp")
        : this.isMisr
        ? require("@/images/ui/man-misr.webp")
        : /*  : this.isSilkWay
          ? require("@/images/ui/man-silk-way.webp")*/
          require("@/images/man.webp");
    },
  },
  methods: {},
  watch: {
    stepCount(newStepCount) {
      if (newStepCount === 0) {
        this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(0, 3);
      } else {
        const startIndex = newStepCount - 1;
        this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(startIndex, startIndex + 3);
      }
    },
  },
  created() {
    if (this.stepCount === 0) {
      this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(0, 3);
    } else {
      const startIndex = this.stepCount - 1;
      this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(startIndex, startIndex + 3);
    }
  },
});
