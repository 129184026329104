import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["carpet-nice", { 'carpet-nice__hidden': !_ctx.showAnimation }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["carpet-nice__item", _ctx.styles])
    }, null, 2),
    (!_ctx.isMevahoGold)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["carpet-nice__roll", _ctx.styles])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}