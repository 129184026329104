
import { defineComponent } from "vue";
import store from "@/store";
import { UIVersion } from "@/version";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "auto-play",
  data() {
    return {};
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    uiVersion(): UIVersion | undefined {
      return store.state.uiVersion;
    },
    autoGame(): boolean {
      return store.state.ui.autoGame;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
  },
  methods: {
    playAudio() {
      if (this.autoGame) {
        AudioUtils.playSound("stop-autoplay", 0, 1);
      } else {
        AudioUtils.playSound("launch-autoplay-button", 0, 1);
      }
    },
    changeChecked() {
      this.playAudio();
      store.commit("setAutoGame", !this.autoGame);
    },
  },
});
