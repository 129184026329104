
import { defineComponent } from "vue";
import InputControl from "@/components/UI/InputControl.vue";
import ButtonControl from "@/components/UI/ButtonControl.vue";
import AutoPlay from "@/components/UI/AutoPlayButton.vue";
import BigButton from "@/components/UI/BigButton.vue";
import store from "@/store";
import NominalGroup from "@/components/NominalGroup.vue";
import CombinationButton from "@/components/UI/CombinationButton.vue";
import NominalButton from "@/components/UI/NominalButton.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MobileButton from "@/components/UI/MobileButton.vue";
import { CLOSE_DOUBLING_AFTER_LOST_TIMEOUT } from "@/constants";

export default defineComponent({
  name: "purchase-panel",
  components: {
    MobileButton,
    MyButton,
    NominalButton,
    NominalGroup,
    BigButton,
    AutoPlay,
    ButtonControl,
    InputControl,
    CombinationButton,
  },
  data() {
    return {
      autoPlay: false,
      sumPaid: !!store.state.ui.lastPayout
        ? store.state.ui.lastPayout
        : (undefined as undefined | number),
    };
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    newNominal(): number | undefined {
      return store.state.createTicket.amount ? store.state.createTicket.amount / 100 : undefined;
    },
    isAutoPlay: {
      get(): boolean {
        return this.autoPlay;
      },
      set(value: boolean) {
        this.autoPlay = value;
      },
    },
    newCombinationNumber(): number {
      return store.getters.newCombinationNumber;
    },
    balance(): number | undefined {
      return store.state.userData.balance;
    },
    sumAmount(): number | undefined {
      return store.state.createTicket.sumAmount;
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isTakePayout(): boolean {
      return store.state.doubling.isTakePayout;
    },
    isSplitAvailable(): boolean {
      const split = store.state.settings.doubling_game.split;
      return split.enabled && split.stage_number > 0;
    },
    currentAmount(): number {
      return store.state.doubling.currentAmount;
    },
    deferredBalance(): number {
      return store.state.doubling.deferredBalance;
    },
  },
  methods: {},
  watch: {
    "$store.state.ui.lastPayout": function (n: number) {
      const delay = this.isDoublingOn && this.isFruitMode ? CLOSE_DOUBLING_AFTER_LOST_TIMEOUT : 0;
      if (n > 0) {
        this.sumPaid = n;
      } else {
        this.sumPaid = 0;
        setTimeout(() => {
          this.sumPaid = undefined;
        }, delay);
      }
    },
  },
});
