import { TicketCombination } from "@/types/tickets";
import { GameId } from "@/types/user";

export type TicketCombinationsItem = {
  array: string[];
  number: number;
  matrix: number[][];
};

export const TICKET_COMBINATIONS: { [key in TicketCombination]: TicketCombinationsItem } = {
  "combi-1": {
    array: ["В1-В2-В3-В4-В5"],
    number: 1,
    matrix: [[5, 6, 7, 8, 9]],
  },
  "combi-3": {
    array: ["А1-А2-А3-А4-А5", "В1-В2-В3-В4-В5", "С1-С2-С3-С4-С5"],
    number: 3,
    matrix: [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
      [10, 11, 12, 13, 14],
    ],
  },
  "combi-5": {
    array: [
      "А1-А2-А3-А4-А5",
      "В1-В2-В3-В4-В5",
      "С1-С2-С3-С4-С5",
      "А1-В2-С3-В4-А5",
      "С1-В2-А3-В4-С5",
    ],
    number: 5,
    matrix: [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
      [10, 11, 12, 13, 14],
      [0, 6, 12, 8, 4],
      [10, 6, 2, 8, 14],
    ],
  },
  "combi-7": {
    array: [
      "А1-А2-А3-А4-А5",
      "В1-В2-В3-В4-В5",
      "С1-С2-С3-С4-С5",
      "А1-В2-С3-В4-А5",
      "С1-В2-А3-В4-С5",
      "B1-A2-A3-A4-B5",
      "B1-C2-C3-C4-B5",
    ],
    number: 7,
    matrix: [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
      [10, 11, 12, 13, 14],
      [0, 6, 12, 8, 4],
      [10, 6, 2, 8, 14],
      [5, 1, 2, 3, 9],
      [5, 11, 12, 13, 9],
    ],
  },
  "combi-9": {
    array: [
      "А1-А2-А3-А4-А5",
      "В1-В2-В3-В4-В5",
      "С1-С2-С3-С4-С5",
      "А1-В2-С3-В4-А5",
      "С1-В2-А3-В4-С5",
      "B1-A2-A3-A4-B5",
      "B1-C2-C3-C4-B5",
      "A1-A2-B3-C4-C5",
      "C1-C2-B3-A4-A5",
    ],
    number: 9,
    matrix: [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
      [10, 11, 12, 13, 14],
      [0, 6, 12, 8, 4],
      [10, 6, 2, 8, 14],
      [5, 1, 2, 3, 9],
      [5, 11, 12, 13, 9],
      [0, 1, 7, 13, 14],
      [10, 11, 7, 3, 4],
    ],
  },
};

export const PAYMENT_ODDS: { [key in GameId]: number[][] } = {
  "loto-mevaho-5": [
    [0, 0, 10, 50, 250],
    [0, 5, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 50, 200, 500],
    [0, 0, 50, 200, 500],
    [0, 0, 100, 1000, 5000],
  ],
  "loto-gambusaki-9": [
    [0, 0, 2, 15, 50],
    [0, 0, 5, 20, 100],
    [0, 0, 5, 20, 100],
    [0, 0, 5, 20, 100],
    [0, 0, 10, 40, 200],
    [0, 0, 10, 40, 200],
    [0, 5, 15, 65, 250],
    [0, 5, 25, 100, 500],
    [0, 5, 30, 200, 1000],
    [0, 10, 80, 1000, 5000],
  ],
  "loto-gambusaki-gold-9": [
    [0, 0, 2, 15, 50],
    [0, 0, 5, 20, 100],
    [0, 0, 5, 20, 100],
    [0, 0, 5, 20, 100],
    [0, 0, 10, 40, 200],
    [0, 0, 10, 40, 200],
    [0, 5, 15, 65, 250],
    [0, 5, 25, 100, 500],
    [0, 5, 30, 200, 1000],
    [0, 10, 80, 1000, 5000],
  ],
  "loto-khoja": [
    [0, 2, 5, 20, 500],
    [0, 2, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 10, 50, 125],
    [0, 0, 10, 50, 125],
    [0, 0, 15, 75, 250],
    [0, 0, 15, 75, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 25, 125, 750],
    [0, 2, 25, 125, 750],
    [0, 10, 250, 2500, 9000],
  ],
  "loto-kissaho": [
    [0, 2, 5, 20, 250],
    [0, 0, 5, 30, 100],
    [0, 0, 10, 40, 125],
    [0, 0, 10, 40, 125],
    [0, 0, 10, 40, 125],
    [0, 0, 20, 50, 150],
    [0, 0, 20, 50, 150],
    [0, 0, 30, 75, 250],
    [0, 0, 30, 75, 250],
    [0, 0, 50, 125, 500],
    [0, 0, 75, 250, 1000],
    [0, 0, 75, 250, 1000],
    [0, 20, 200, 2000, 10000],
  ],
  "loto-pearl": [
    [0, 2, 5, 20, 500],
    [0, 2, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 10, 50, 125],
    [0, 0, 10, 50, 125],
    [0, 0, 15, 75, 250],
    [0, 0, 15, 75, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 25, 125, 750],
    [0, 2, 25, 125, 750],
    [0, 10, 250, 2500, 9000],
  ],
  "loto-silk-way": [
    [0, 2, 5, 20, 500],
    [0, 2, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 10, 50, 125],
    [0, 0, 10, 50, 125],
    [0, 0, 15, 75, 250],
    [0, 0, 15, 75, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 25, 125, 750],
    [0, 2, 25, 125, 750],
    [0, 10, 250, 2500, 9000],
  ],
  "loto-misr": [
    [0, 2, 5, 20, 500],
    [0, 2, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 10, 50, 125],
    [0, 0, 10, 50, 125],
    [0, 0, 15, 75, 250],
    [0, 0, 15, 75, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 25, 125, 750],
    [0, 2, 25, 125, 750],
    [0, 10, 250, 2500, 9000],
  ],
  "loto-pamir-legends": [
    [0, 2, 4, 20, 500],
    [0, 2, 4, 30, 100],
    [0, 0, 4, 30, 100],
    [0, 0, 4, 30, 100],
    [0, 0, 4, 30, 100],
    [0, 0, 10, 50, 120],
    [0, 0, 10, 50, 120],
    [0, 0, 20, 70, 250],
    [0, 0, 20, 70, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 30, 120, 800],
    [0, 2, 30, 120, 800],
    [0, 10, 250, 2500, 9000],
  ],
  "loto-mevaho-gold": [
    [0, 0, 10, 50, 250],
    [0, 5, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 20, 50, 200],
    [0, 0, 50, 200, 500],
    [0, 0, 50, 200, 500],
    [0, 0, 100, 1000, 5000],
  ],

  "loto-astrologer": [
    [0, 2, 5, 20, 500],
    [0, 2, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 5, 25, 100],
    [0, 0, 10, 50, 125],
    [0, 0, 10, 50, 125],
    [0, 0, 15, 75, 250],
    [0, 0, 15, 75, 250],
    [0, 0, 20, 100, 400],
    [0, 2, 25, 125, 750],
    [0, 2, 25, 125, 750],
    [0, 10, 250, 2500, 9000],
  ],
};

export const GAMBUSAKI_ZEROS_MATRIX = [
  [0, 5, 10],
  [1, 6, 11],
  [2, 7, 12],
  [3, 8, 13],
  [4, 9, 14],
];

export const GAMBUSAKI_TEN = [2, 7, 12];

export const DESIGNATIONS_MEVAHO_LINKS = [
  "payments-star.png",
  "payments-cherry.png",
  "payments-lemon.png",
  "payments-orange.png",
  "payments-plum.png",
  "payments-grape.png",
  "payments-watermelon.png",
  "payments-seven.png",
];

export const DESIGNATIONS_MEVAHO_GOLD_LINKS = [
  "mg-pear.webp",
  "mg-cherry.webp",
  "mg-lemon.webp",
  "mg-orange.webp",
  "mg-apple.webp",
  "mg-pineapple.webp",
  "mg-watermelon.webp",
  "mg-seven.webp",
];

export const DESIGNATIONS_GUMBUSAKI_LINKS = [
  "note.png",
  "cube-with-1-dot.png",
  "cube-with-2-dot.png",
  "cube-with-3-dot.png",
  "cube-with-4-dot.png",
  "cube-with-5-dot.png",
  "cube-with-6-dot.png",
  "butterfly.png",
  "snail.png",
  "bee.png",
  "ladybug.png",
];

export const DESIGNATIONS_KHOJA_LINKS = [
  "static-0.webp",
  "static-1.webp",
  "static-2.webp",
  "static-3.webp",
  "static-4.webp",
  "static-5.webp",
  "static-6.webp",
  "static-7.webp",
  "static-8.webp",
  "static-9.webp",
  "static-10.webp",
  "static-11.webp",
  "static-12.webp",
];

export const DESIGNATIONS_PEARL_LINKS = [
  "pearl-0.webp",
  "pearl-1.webp",
  "pearl-2.webp",
  "pearl-3.webp",
  "pearl-4.webp",
  "pearl-5.webp",
  "pearl-6.webp",
  "pearl-7.webp",
  "pearl-8.webp",
  "pearl-9.webp",
  "pearl-10.webp",
  "pearl-11.webp",
  "pearl-12.webp",
];

export const DESIGNATIONS_KISSAHO_LINKS = [
  "kissaho-0.webp",
  "kissaho-1.webp",
  "kissaho-2.webp",
  "kissaho-3.webp",
  "kissaho-4.webp",
  "kissaho-5.webp",
  "kissaho-6.webp",
  "kissaho-7.webp",
  "kissaho-8.webp",
  "kissaho-9.webp",
  "kissaho-10.webp",
  "kissaho-11.webp",
  "kissaho-12.webp",
];

export const DESIGNATIONS_SILKWAY_LINKS = [
  "silkway-0.webp",
  "silkway-1.webp",
  "silkway-2.webp",
  "silkway-3.webp",
  "silkway-4.webp",
  "silkway-5.webp",
  "silkway-6.webp",
  "silkway-7.webp",
  "silkway-8.webp",
  "silkway-9.webp",
  "silkway-10.webp",
  "silkway-11.webp",
  "silkway-12.webp",
];

export const DESIGNATIONS_MISR_LINKS = [
  "misr-0.webp",
  "misr-1.webp",
  "misr-2.webp",
  "misr-3.webp",
  "misr-4.webp",
  "misr-5.webp",
  "misr-6.webp",
  "misr-7.webp",
  "misr-8.webp",
  "misr-9.webp",
  "misr-10.webp",
  "misr-11.webp",
  "misr-12.webp",
];

export const DESIGNATIONS_PAMIR_LINKS = [
  "pamir-0.webp",
  "pamir-1.webp",
  "pamir-2.webp",
  "pamir-3.webp",
  "pamir-4.webp",
  "pamir-5.webp",
  "pamir-6.webp",
  "pamir-7.webp",
  "pamir-8.webp",
  "pamir-9.webp",
  "pamir-10.webp",
  "pamir-11.webp",
  "pamir-12.webp",
];

export const DESIGNATIONS_ASTROLOGER_LINKS = [
  "astro-0.webp",
  "astro-1.webp",
  "astro-2.webp",
  "astro-3.webp",
  "astro-4.webp",
  "astro-5.webp",
  "astro-6.webp",
  "astro-7.webp",
  "astro-8.webp",
  "astro-9.webp",
  "astro-10.webp",
  "astro-11.webp",
  "astro-12.webp",
];

export const DELAY_STATUSES = [
  "won",
  "lost",
  "gamestop",
  "internal-error",
  "out-of-limits",
  "manually-returned",
  "insufficient-funds",
];

export const OPEN_BOX_TIMEOUT = 1 * 2000;

export const OPEN_SECOND_BOX_TIMEOUT = 1 * 2000;

export const OPEN_BOX_TIMEOUT_SILK_WAY = 1 * 3000;

export const TYPICAL_ANIMATION_TIME = 1 * 1000;

export const TYPICAL_ANIMATION_TIME_DIGIT = 1.5 * 1000;

export const MAKE_TICKET_DELAY = 1.5 * 1000;

export const MAKE_TICKET_DELAY_DIGIT = 1 * 1000;

export const AUTO_DOUBLING_CLOSE_TIMER = 3 * 1000;

export const CLOSE_DOUBLING_AFTER_LOST_TIMEOUT = 1.5 * 1000;

export const RESET_ERROR_STATUS_TIMEOUT = 5 * 1000;

export const FRUIT_AUTOPLAY_LOST_DELAY = 1 * 1000;

export const AUTOPLAY_AFTER_DOUBLING_DELAY = 1 * 1000;

export const PLAY_BONUS_AFTER_RECONNECT = 3 * 1000;

export const CLOSE_BONUS_IN_BONUS_MODAL = 2 * 1000;

export const BONUS_IN_BONUS_ANIM_DELAY = 3.1 * 1000;

export const COEFFICIENT_MULTIPLY_KHOJA = 3;

export const COEFFICIENT_MULTIPLY_PEARL = 3;
